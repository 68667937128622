.login {
  &-layout {
    display: flex;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  &-form-layout {
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px solid black;
    border-radius: 5px;
    height: 30rem;
    position: absolute;
    top: 12%;
    right: 5%;
    text-align: left;
  }

  &-logo {
    // border: 2px solid yellow;
    width: 13rem;
    height: 5rem;
    padding: 8px;
    margin: 28px;
  }

  &-header {
    // border: 2px solid green;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 30px;
    padding: 0px 41px;
  }

  &-form {
    // border: 2px solid blue;
  }

  &-input {
    // border: 2px solid indigo;
    width: 30rem;
  }

  &-cta {
    // border: 2px solid violet;
    justify-content: flex-end;
    margin-top: 30px;
  }

  // &-captcha {
  //   margin-top: 2rem;
  //   justify-content: center;
  // }

  // &-captcha div {
  //   margin: 0 auto;    
  //   padding: 1rem 0px;    
  // }

  &-btn {
    margin-right: 2.9rem;
  }

  &-footer{
    position: absolute;
    bottom: 12px;
    right: 15px;
    font-size: 12px;
  }

  &-footer2 {
    position: absolute;
    bottom: 12px;
    left: 15px;
    font-size: 12px;
  }
  
  &-footer2 > a {
    text-decoration: none;
    color: darkslategrey;
  }  
}

@font-face {
  font-family: ApercuBold;
  // src: url('../fonts/ApercuBold.ttf');
}

@font-face {
  font-family: ApercuRegular;
  // src: url('../fonts/ApercuRegular.ttf');
}

#loader {
  // border: 16px solid #f3f3f3;
  // border-radius: 50%;
  // border-top: 16px solid #3498db;
  position: absolute;
  top: 128px;
  left: 128px;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

#frame {
  position: relative;
  width: 18rem;
}

#loader {
  top: 5rem;
  left: 6rem;
  width: 4rem;
  height: 4rem;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
  }

/* Portrait */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

}

/* Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
}


/* Portrait huawei portrait real */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  .login {
    &-layout {
      // border: 2px solid pink;
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      width: 95%;
      margin: 4rem auto;
      text-align: center;
      position: unset;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-form-container {
      // border: 2px solid orange;
      margin-top: 0%;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-input {
      // border: 2px solid indigo;
      width: 90%;
    }

    &-cta {
      // border: 2px solid violet;
      align-self: center;
      margin-top: 0rem;
    }

    &-btn {
      width: 10rem;
      margin: 0rem auto;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}

/* Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape iphonex 11 real */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

  .login {
    &-layout {
      // border: 2px solid red;
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }


    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}


/* Portrait iphoneSE xcode simulator */
@media only screen
  and (device-width: 375px)
  and (device-height: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* glaxy fold Portrait */
@media only screen
  and (device-width: 280px)
  and (device-height: 653px)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}

/* Portrait */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

    #content {
      color: black;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

    #content {
      color: black;
    }
}


/* surface duo dev tools */
@media only screen
  and (device-width: 540px)
  and (device-height: 720px)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}


/* glaxy fold landscape */
@media only screen
  and (device-width: 653px)
  and (device-height: 280px)
  and (-webkit-min-device-pixel-ratio: 3) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}



/* pixel 2 dev tools landscape */
@media only screen
  and (device-width: 731px)
  and (device-height: 411px)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}
/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {

}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

    #content {
      color: black;
    }
}

/* Landscape huawei landscape real*/
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}

/* I P A D */
/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {

    #content {
      color: black;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1) {

    #content {
      color: black;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) {

    #content {
      color: black;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #content {
      color: black;
    }
}

/* Portrait m IPAD portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }

}

/* Landscape  m IPHONEX landscape dev tools */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }


    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 834px)
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #content {
      color: black;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
  and (min-device-width: 834px)
  and (max-device-width: 834px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

    #content {
      color: black;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
  and (min-device-width: 1112px)
  and (max-device-width: 1112px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

    #content {
      color: black;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #content {
      color: black;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {
    #content {
      color: black;
    }
}

/* Landscape m IPAD landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
  and (min-device-width: 1366px)
  and (max-device-width: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {


  .login {
    &-layout {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-form-layout {
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid black;
      border-radius: 5px;
      height: 30rem;
      position: absolute;
      top: 12%;
      right: 5%;
      text-align: left;
    }

    &-logo {
      // border: 2px solid yellow;
      width: 13rem;
      height: 5rem;
      padding: 8px;
      margin: 28px;
    }

    &-header {
      // border: 2px solid green;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 30px;
      padding: 0px 41px;
    }

    &-form {
      // border: 2px solid blue;
    }

    &-input {
      // border: 2px solid indigo;
      width: 30rem;
    }

    &-cta {
      // border: 2px solid violet;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &-btn {
      margin-right: 2.9rem;
    }

    &-footer{
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 12px;
    }
  }
}

@btn-border-radius-base: 8px;